@font-face {
  font-family: "Quicksand-Regular";
  src: url("./fonts/Quicksand/Quicksand-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand-bold";
  src: url("./fonts/Quicksand/Quicksand-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand-medium";
  src: url("./fonts/Quicksand/Quicksand-Medium.ttf") format("truetype");
}
body {
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family: "Quicksand-Regular";
  color: #000;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
p {
  font-family: "Quicksand-medium";
  font-size: 14px;
  margin: 0;
  text-align: center;
  color: #000000;
}
h1 {
  font-family: "Quicksand-bold";
  font-size: 28px;
  color: #6d3178;
  text-align: center;
}
h2 {
  font-family: "Quicksand-bold";
  color: #f37436;
  font-size: 25px;
  line-height: 160%;
  text-align: center;
}
h3 {
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #f37436;
}
a {
  color: #f37436;
  cursor: pointer;
  text-decoration: none !important;
}
fieldset{
  border:none !important;
}

.css-dmvucb-MuiGrid-root>.MuiGrid-item {
  padding: 0px !important;
}
.css-1b2erga-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled{
  background-color: #f5f5f5;
}

.custom-select {
  font-size: 16px;
}

table {
  width: 100%;
}
.orange {
  color: #f37436 !important;
}
.light-grey {
  color: #cdcdcd;
}
.gray {
  color: #8d8d8d;
}
.center {
  text-align: center !important;
}
.clearfix {
  clear: both;
}
.clearfix::after,
.clearfix::before {
  content: "";
  clear: both;
  display: table;
}
.check-style {
  padding-bottom: 5px !important;
}
.experience-label {
  margin: 12px 5px;
  color: #8d8d8d;
}
.p-small {
  font-size: 12px;
  text-align: left;
  margin: 10px 20px;
  color: #8d8d8d;
}
.p-label {
  font-size: 13px;
  text-align: left;
  padding-bottom: 10px;
  color: #8d8d8d;
}
.p-large {
  font-size: 14px !important;
  padding: 0px 22px;
  margin-bottom: 25px;
  text-align: center;
}
/* Common css end */
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}
/* Button style start */
.btn-wrap {
  margin: 16px;
  text-align: center;
  text-transform: capitalize !important;
}
.btn-outline {
  background: #ffffff;
  color: #f37436;
}
.btn-outline .mat-button-ripple {
  border: 1px solid #f37436;
}
.btn-clear {
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(205, 205, 205, 0.4),
    0px 0px 12px rgba(205, 205, 205, 0.4), 0px 0px 12px rgba(205, 205, 205, 0.4);
  border-radius: 4px;
  height: 38px;
  color: #f37436;
  margin-top: 8px !important;
}
.btn-dialog .mat-button {
  padding: 0 5px !important;
  margin: 0 3px !important;
  font-size: 14px;
}
/* Button style end */

/* Change the white to any color ;) */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset;
  -webkit-box-shadow: 0 0 0 30px white inset;
}
input:focus {
  outline: none;
}
input[type="file" i] {
  display: none;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #f37436 !important;
  border-radius: 10px;
}
/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #f37436 !important;
}

.site-header {
  padding: 10px;
  box-shadow: 0px 0px 9px rgba(205, 205, 205, 0.4),
    0px 0px 12px rgba(205, 205, 205, 0.4), 0px 0px 12px rgba(205, 205, 205, 0.4);
}
.logo-wrap {
  float: left;
}
.logo-wrap-img {
  width: 22%;
}
.urn {
  float: right;
  padding: 15px 0;
  color: #f37436;
}
.mt-10{
  margin-top: 10px;
}
.text-center{
  text-align: center;
}
/* form style start*/
.row {
    padding: 10px 0;
}
.form-group{
    padding: 30px 0
  }
.form-row{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
  }
.form-col,.form-col-50{
    width: calc(50% - 30px);
    margin-right: 30px;
}
.form-col-auto{
    width: 100%;
  }
.form-col-3{
    width: calc(33% - 30px);
    margin-right: 30px
  }
.form-col-3:last-child,.form-col:last-child,.form-col-50:last-child{
  margin-right: 0;
}
/* form style end */

/* Step style starts */
.pixie-container {
  display: flex;
  min-height: calc(100vh - 120px);
}
.left-container {
  display: none;
}
.right-container {
  width: 100%;
}
.section-container {
  max-width: 425px;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}
.tab-content-wrapper {
  padding: 20px;
}
.step {
  padding: 10px;
  box-shadow: 8px -8px 8px rgba(205, 205, 205, 0.2),
    -8px -8px 8px rgba(205, 205, 205, 0.2),
    0px 0px 20px rgba(205, 205, 205, 0.3);
  border-radius: 4px;
}
.step.next .step-body {
  display: none;
}
.step.next {
  opacity: 0.5;
}
.step.active .step-body {
  display: none;
}
.step-image {
  max-width: 100%;
}
/* Step style ends */

.footer-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  text-align: center;
  color: #f37436;
  margin: 15px;
}
.login_tab_group{
  margin-top: 25px;
}
.link-resume{
  margin-left: 10px
}
.existing-customer-wrap{
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.links{
  margin: 8px;
}
/* otp verification screen style starts */
.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
.close-icon i {
  color: #000;
}

/* otp verification screen style ends */

/* User info style start */
.terms {
  margin-top: 20px;
}
.user-info {
  box-shadow: 8px -8px 8px rgba(205, 205, 205, 0.2),
    -8px -8px 8px rgba(205, 205, 205, 0.2),
    0px 0px 20px rgba(205, 205, 205, 0.3);
  border-radius: 4px;
  padding: 10px 30px;
  margin-bottom: 10px;
}
.user-info h2 {
  color: #f37436;
  margin: 0;
  line-height: 120%;
  font-size: 28px;
  padding: 5px;
}
.user-info p {
  color: #8d8d8d;
}

.user-info.half:first-child {
  margin-right: 16px;
}
.user-info.half h2 {
  font-size: 22px;
}
.profile-img {
  width: 140px;
  height: 140px;
  margin: 20px auto;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(205, 205, 205, 0.4),
    0px 9px 3px rgba(205, 205, 205, 0.3), 0px -2px 12px rgba(205, 205, 205, 0.4);
}
.profile-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: fill;
}
/* User info style end */

/* loan and tenure selection style start*/
.new-user_tab_footer{
  margin-top: 20px;
  text-align: center;
}
.form-row.loan-types {
  display: flex;
  padding: 5px 0;
  justify-content: center;
}
.loan-range {
  color: #f37436;
}
.loan-range .less-range {
  float: left;
  margin-left: 25px;
}
.loan-range .more-range {
  float: right;
}
.loan-types .label-content {
  padding: 0;
  display: block;
  margin: 5px 0 10px 0;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}
.loan-types .radio-img {
  width: 71px !important;
  height: 65px !important;
  display: block;
  margin: 0 auto;
  padding-top: 7px;
  background: transparent url("./images/wedding.svg") center center no-repeat;
}

.loan-types .radio-img.wedding {
  background: transparent url("./images/wedding.svg") center center no-repeat;
}
.loan-types .radio-img.education {
  background: transparent url("./images/education.svg") center center no-repeat;
}

.loan-types .radio-img.renovation {
  background: transparent url("./images/renovation.svg") center center
    no-repeat;
}
.loan-types .radio-img.travel {
  background: transparent url("./images/travel.svg") center center no-repeat;
}

.loan-types .radio-img.medical {
  background: transparent url("./images/medical.svg") center center no-repeat;
}
.loan-types .radio-img.occasion {
  background: transparent url("./images/ocassion.svg") center center no-repeat;
}

.mat-select-chip {
  width: 100px !important;
  height: 142px !important;
  display: block;
  margin: 10px 0 0 5px;
  padding-top: 7px;
  background: transparent url("./images/ocassion.svg") center center no-repeat;
  background-position: 16px 22px;
  padding: 0;
  cursor: pointer;
  box-shadow: 0px 0px 9px rgba(205, 205, 205, 0.4),
    0px 0px 12px rgba(205, 205, 205, 0.4), 0px 0px 12px rgba(205, 205, 205, 0.4);
  border-radius: 4px;
}
.mat-select-chip .mat-select-trigger {
  top: 96px;
}
.mat-select-chip .mat-select-placeholder {
  color: #000000;
  padding-left: 20px;
}

.year-month-field .p-label {
  padding: 0;
  margin: 0;
}
/* loan and tenure selection style end */

/* Document upload style start */
.upload-doc-wrapper {
  align-items: center;
  display: flex;
  padding: 5px 0;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.upload-doc {
  cursor: pointer;
  box-shadow: 0px 0px 9px rgba(205, 205, 205, 0.4),
    0px 0px 12px rgba(205, 205, 205, 0.4), 0px 0px 12px rgba(205, 205, 205, 0.4);
  border-radius: 4px;
  width: 116px;
  height: 120px;
  text-align: center;
  margin: 15px 14px;
}
.upload-doc label {
  cursor: pointer;
}
.upload-doc .upload-icon {
  width: 72px;
  height: 68px;
  display: block;
  margin: 0 auto;
}

/* loan sanctioned  style start */
.loan-detail .label {
  font-size: 12px;
  color: #8d8d8d;
}
.loan-detail a {
  font-size: 12px;
  color: #8d8d8d;
}
.loan-detail span {
  padding-left: 17px;
}
/* loan sanctioned  style end */

/* loan Processed  style start */
.loan-processed img {
  display: block;
  margin: 0 auto;
}
.loan-processed p {
  margin: 15px 40px;
}
.loan-processed-footer {
  margin: 20px 30px;
}
.loan-processed-footer p {
  font-family: Quicksand;
  line-height: 30px;
  color: #6d3178;
  padding-right: 35px;
  background: transparent url("./images/pdf.svg") center right no-repeat;
  background-position: 259px 6px;
}
.loan-processed-footer .share-img {
  background: transparent url("./images/share.svg") center right no-repeat;
  background-position: 295px 6px;
}
/* loan Processed  style end */

.error-body {
  margin: 0 auto;
  padding: 30px;
}
.full-width {
  width: 100%;
}
.loading-text {
  margin: 120px 0px 0px 0px;
  font-size: 18px;
}
.header-bg{
  background-color: #fff !important;

}

.inside-card{
  background-color: #e3e3e3 !important;
}

.logo{
  text-align: center;
  margin: 8px;
}

@media only screen and (max-width:420px)
{
  .btn-wrap{
      margin: 16px 0px;
  }
  .form-col-50{
    margin: 5px 8px 5px 0px;
  }
  .loan-processed-footer {
    margin: 0px;
  }
  .user-info{
    padding: 10px;
  }
}
@media only screen and (min-width:420px)
{
  .btn-dialog .mat-button{
    margin: 0 5px !important;
    padding: 0 16px !important;
    font-size: 16px;
  }

  .btn-dialog{
    text-align: center;
  }
}
@media only screen and (min-width:1020px)
{
  .upload-doc-wrapper{
      width: 50%;
      padding-bottom: 20px;
      margin: 0 auto;
  }
}
@media (max-width:768px){
  .form-col-3{
    margin-right: 0;
    width: 100%;
  }
}
@media only screen and (max-width:760px){

  .user-info.half {
      width: calc(50% - 8px);
      display: inline-block;
      box-sizing: border-box;
  }
}
@media only screen and (min-width:760px)
{
    .left-container {
        float: left;
        width: 40%;
        display: block;
        text-align: center;
    }
    .right-container{
        float: right;
        width: 60%;
        padding-right: 30px;
    }
    .section-container{
        max-width: 100%;
    }

    .g-label{
      padding: 18px 15px 0 0;
    }
    .loan-processed-footer .share-img {
      background-position: 471px 6px;
    }
    .loan-processed-footer p {
      background-position: 435px 6px;
    }
}
